<template>
  <div>
    
    <div class="row">

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-start mb-5">
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisWeek"
                @click="Filter('thisWeek')"
              >
                Minggu Ini
              </button>
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisMonth"
                @click="Filter('thisMonth')"
              >
                Bulan Ini
              </button>
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.lastMonth"
                @click="Filter('lastMonth')"
              >
                Bulan Kemarin
              </button>
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisYear"
                @click="Filter('thisYear')"
              >
                Tahun Ini
              </button>
              <!-- <button
              type="button"
              class="btn btn-outline-primary filter-btn mx-1"
              @click="periodFilter()"
            ></button> -->
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <apexchart
          class="card-rounded-bottom"
          :options="chartOptions"
          :series="series"
          type="bar"
          width="100%"
          height="300"
          
        ></apexchart>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
export default {
  name: "widget-12",
  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "Jumlah :",
          data: [],
        },
      ],
      sum: "",
      arr: [],
      chartLoad: false,
      sumLoad: false,
      // filter
      filter: {
        start_date: "",
        end_date: "",
      },
      period: {
        thisWeek: "btn-purple",
        thisMonth: "btn-outline-purple",
        lastMonth: "btn-outline-purple",
        thisYear: "btn-outline-purple",
      },
      
      tooltipPeriod: "thisWeek",

      // chart
      // series: [],
      chartOptions: {
        chart: {
          type: "bar",
          
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
            distributed: true,
            borderRadius: 10
          },
        },
        dataLabels: {
          enabled: false,
        },
        // stroke: {
        //   show: true,
        //   width: 2,
        //   colors: ['transparance'],
        // },
        xaxis: {
          categories: [],
        },
        // yaxis: {
        //   title: {
        //     text: "$ (thousands)",
        //   },
        // },
        fill: {
          opacity: 1,
        },
        colors:['#245590','#f47b20','#5c4ea5'],
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    };
  },
  methods: {
    Filter(tool) {
      this.tooltipPeriod = tool;
      this.chartLoad = false;

      if (tool == "thisWeek") {
        this.period.thisWeek = "btn-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "lastMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisYear") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-purple";
      }

      this.$root.$emit("filterChart", tool);
      
      this.getData();

      mapGetters(["layoutConfig"]);
    },
    // generalFilter() {
    //   this.tooltipPeriod = "custom";
    //   this.chartLoad = false;
    //   this.getData();
    //   mapGetters(["layoutConfig"]);
    // },
    // periodFilter() {
    //   this.tooltipPeriod = "custom";
    // },
    // resetFilter() {
    //   this.chartLoad = false;
    //   this.filter.start_date = "";
    //   this.filter.end_date = "";
    //   this.getData();
    //   mapGetters(["layoutConfig"]);
    // },

    async getData(tool) {
      window.arrData = [];
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get("graphic-patient/age", `${filterParams}`);
      
      let patient = [];
    
      let category1 = [],
          category2 = [],
          category3 = [],
          category4 = [],
          category5 = [],
          category6 = [],
          category7 = []
      
      let amount1 = 0,
          amount2 = 0,
          amount3 = 0,
          amount4 = 0,
          amount5 = 0,
          amount6 = 0,
          amount7 = 0


      console.log(response.items,"response.items");

      for (let a = 0 ;a < response.items.length; a++) {
        if(response.items[a].age >= 0 && response.items[a].age <=5){
          category1.push(response.items[a])
        }else if(response.items[a].age > 5 && response.items[a].age <=12){
          category2.push(response.items[a])
        }else if(response.items[a].age > 12 && response.items[a].age <=17){
          category3.push(response.items[a])
        }else if(response.items[a].age > 17 && response.items[a].age <=25){
          category4.push(response.items[a])
        }else if(response.items[a].age > 25 && response.items[a].age <=35){
          category5.push(response.items[a])
        }else if(response.items[a].age > 35 && response.items[a].age <=45){
          category6.push(response.items[a])
        }else if(response.items[a].age > 45){
          category7.push(response.items[a])
        }
      }

      //  0-5 tahun
      for (let b = 0; b < category1.length; b++) {
        amount1 = amount1 + category1[b].amount        
      }

      console.log(amount1,"amount1");

      patient.push({
        ref_name: "0-5" ,
        price: amount1,
      });

      // 6-12 tahun
      for (let c = 0; c < category2.length; c++) {
        amount2 = amount2 + category2[c].amount        
      }

      patient.push({
        ref_name: "6-12" ,
        price: amount2,
      });

       // 13-17 tahun
      for (let d = 0; d < category3.length; d++) {
        amount3 = amount3 + category3[d].amount        
      }

      patient.push({
        ref_name: "13-17" ,
        price: amount3,
      });
      
      // 18-25 tahun
      for (let e = 0; e < category4.length; e++) {
        amount4 = amount4 + category4[e].amount        
      }

      patient.push({
        ref_name: "18-25" ,
        price: amount4,
      });

       // 26-35 tahun
      for (let f = 0; f < category5.length; f++) {
        amount5 = amount5 + category5[f].amount        
      }

      patient.push({
        ref_name: "26-35" ,
        price: amount5,
      });

       // 36-45 tahun
      for (let g = 0; g < category6.length; g++) {
        amount6 = amount6 + category6[g].amount        
      }

      patient.push({
        ref_name: "36-45" ,
        price: amount6,
      });

       // over 45 tahun
      for (let h = 0; h < category7.length; h++) {
        amount7 = amount7 + category7[h].amount        
      }

      patient.push({
        ref_name: ">45" ,
        price: amount7,
      });

      this.series[0].data = patient.map((patient) => parseFloat(patient.price));
      window.arrData = patient.map((patient) => String(patient.ref_name));
      this.chartLoad = true;

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: window.arrData,
            title: {
              text: 'Periode Usia',
            },
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.getData();
    if (this.chartLoad == true) {
      
    }

    // this.$root.$on('filterChart',(tool)=>{
    //   console.log(tool,"============ tool ================");
    //   this.Filter(tool)
    // })
    // reference; kt_stats_widget_12_chart
  },
};
</script>


