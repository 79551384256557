<template>
  <div>
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  name: "List",

  components: {
    Card,
  },

  data() {
    return {
      //   graph
      series: [
        {
          data: [0],
          name: "Jumlah Pasien",
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              // console.log(chart, w, e)
            },
          },
        },
        // colors: ['#f47b20', '#245590'],
        // colors: ['#f47b20'],
        colors: ['#245590','#f47b20','#5c4ea5'],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
             borderRadius: 5
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            formatter: function (val) {
              return val;
            },
          },
        },
      },
      // User Access
      btn: false,
    };
  },

  methods: {
    async get() {
      console.log("get distrib graphic");
      let data = await module.getDistributionGraphic(
        "patients/distribution-map/graphic"
      );

      if (data != null) {
        let series = data.series;
        let patientSeries = [];
        console.log("graphic", data);

        // for (let a = 0; a < region.length; a++) {
        //   for (let b = 0; b < patient.length; b++) {
        //     if (region[a].id == patient[b].province_id) {
        //       patientSeries.push(patient[b].total_count);
        //     } else {
        //     //   if (b + 1 == patient.length) {
        //     //     patientSeries.push(0);
        //     //   }
        //     }
        //   }
        // }


        this.series = [
          {
            data: series,
          },
        ];

        this.series = [
          {
            data: series,
          },
        ];


        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories: data.categories,
              labels: {
                style: {
                  fontSize: "12px",
                },
              },
            },
          },
        };
      }
    },
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien" },
      { title: "Peta Sebaran" },
    ]);
    // Get Data
    this.get();
  },
};
</script>
