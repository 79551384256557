<template>
  <div>
    <!--begin::Chart-->

    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-start mb-5">
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisWeek"
              @click="Filter('thisWeek')"
            >
              Minggu Ini
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisMonth"
              @click="Filter('thisMonth')"
            >
              Bulan Ini
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.lastMonth"
              @click="Filter('lastMonth')"
            >
              Bulan Kemarin
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisYear"
              @click="Filter('thisYear')"
            >
              Tahun Ini
            </button>
            <!-- <button
              type="button"
              class="btn btn-outline-primary filter-btn mx-1"
              @click="periodFilter()"
            ></button> -->
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <apexchart
          class="card-rounded-bottom"
          :options="chartOptions"
          :series="series"
          width="100%"
          height="300"
        ></apexchart>
      </div>

     
    </div>
    <!--end::Chart-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
export default {
  name: "widget-12",
  data() {
    return {
      chartOptions: {},
      income: 0,
      outcome: 0,
      // series: [
      //   {
      //     name: "Jumlah ",
      //     data: [],
      //   },
      // ],
      series: [
        {
          name: "Pemasukkan",
          type: "column",
          data: [],
        },
        {
          name: "Pengeluaran",
          type: "column",
          data: [],
        },
      ],
      sum: "",
      arr: [],
      chartLoad: false,
      sumLoad: false,
      // filter
      filter: {
        start_date: "",
        end_date: "",
      },
      period: {
        thisWeek: "btn-purple",
        thisMonth: "btn-outline-purple",
        lastMonth: "btn-outline-purple",
        thisYear: "btn-outline-purple",
      },

      tooltipPeriod: "thisWeek",

      // chart
      // series: [],
      chartOptions: {
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        // stroke: {
        //   show: true,
        //   width: 2,
        //   colors: ['transparance'],
        // },
        xaxis: {
          categories: [],
        },
        // yaxis: {
        //   title: {
        //     text: "$ (thousands)",
        //   },
        // },
        fill: {
          opacity: 1,
        },
        colors: ["#28a745", "#d22d2d"],
        tooltip: {
          fixed: {
            enabled: true,
            position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60,
          },
          y: {
            formatter: function(val) {
              return parseInt(val).toLocaleString("id-ID");
            },
          },
        },

        title: {
          text: "Statistik Klinik",
          align: "left",
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: "14px",
            fontWeight: "bolder",
            // fontFamily:  undefined,
            // color:  '#263238'
          },
        },
      },
    };
  },
  methods: {
    Filter(tool) {
      this.$root.$emit('filterChartMixed',tool)
      this.tooltipPeriod = tool;
      this.chartLoad = false;

      if (tool == "thisWeek") {
        this.period.thisWeek = "btn-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "lastMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisYear") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-purple";
      }

      this.getData();

      mapGetters(["layoutConfig"]);
    },
    // generalFilter() {
    //   this.tooltipPeriod = "custom";
    //   this.chartLoad = false;
    //   this.getData();
    //   mapGetters(["layoutConfig"]);
    // },
    // periodFilter() {
    //   this.tooltipPeriod = "custom";
    // },
    // resetFilter() {
    //   this.chartLoad = false;
    //   this.filter.start_date = "";
    //   this.filter.end_date = "";
    //   this.getData();
    //   mapGetters(["layoutConfig"]);
    // },

    month(month) {
      let monthName;
      if (month == 1) {
        monthName = "Jan";
        // monthName = "Januari"
      } else if (month == 2) {
        monthName = "Feb";
        // monthName = "Februari"
      } else if (month == 3) {
        monthName = "Mar";
        // monthName = "Maret"
      } else if (month == 4) {
        monthName = "Apr";
        // monthName = "April"
      } else if (month == 5) {
        monthName = "Mei";
        // monthName = "Mei"
      } else if (month == 6) {
        monthName = "Jun";
        // monthName = "Juni"
      } else if (month == 7) {
        monthName = "Jul";
        // monthName = "Juli"
      } else if (month == 8) {
        monthName = "Agu";
        // monthName = "Agustus"
      } else if (month == 9) {
        monthName = "Sep";
        // monthName = "September"
      } else if (month == 10) {
        monthName = "Okt";
        // monthName = "Oktober"
      } else if (month == 11) {
        monthName = "Nov";
        // monthName = "November"
      } else if (month == 12) {
        monthName = "Des";
        // monthName = "Desember"
      }

      return monthName;
    },

    async getData(tool) {
      window.arrData = [];
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get("graphic-payment", `${filterParams}`);
      let resOutcome = await module.get("graphic-expense", `${filterParams}`);

      let incomeData = response;
      let outcomeData = resOutcome;

      this.income = parseInt(incomeData.total).toLocaleString("id-ID");
      this.outcome = parseInt(outcomeData.total).toLocaleString("id-ID");

      let income = [];
      let outcome = [];
      let typePeriod = "Tanggal";

      if (
        this.tooltipPeriod != "thisYear" &&
        this.tooltipPeriod != "thisWeek"
      ) {
        let b = 0;
        let counter = 1;
        for (let a = 0; a <= incomeData.days; a++) {
          if (typeof incomeData.items[b] !== "undefined") {
            let monthName = this.month(parseInt(response.items[b].month));

            if (incomeData.items[b].day == counter) {
              income.push({
                ref_name: parseInt(incomeData.items[b].day) + "-" + monthName,
                price: incomeData.items[b].amount,
              });
              b++;
            } else {
              income.push({
                ref_name: parseInt(counter) + "-" + monthName,
                price: 0,
              });
            }
          } else {
            let month;
            if (this.tooltipPeriod == "lastMonth") {
              month = moment()
                .subtract(1, "month")
                .format("MM");
            } else if (this.tooltipPeriod == "thisMonth") {
              month = moment().format("MM");
            }

            month = this.month(parseInt(month));

            income.push({
              ref_name: parseInt(counter) + "-" + month,
              price: 0,
            });
          }
          counter++;
        }

        typePeriod = "Tanggal";
      } else if (this.tooltipPeriod == "thisYear") {
        let b = 1;
        for (let a = 0; a < 12; a++) {
          if (typeof incomeData.items[a] !== "undefined") {
            let monthName;
            if (incomeData.items[a].month == 1) {
              monthName = "Januari";
            } else if (incomeData.items[a].month == 2) {
              monthName = "Februari";
            } else if (incomeData.items[a].month == 3) {
              monthName = "Maret";
            } else if (incomeData.items[a].month == 4) {
              monthName = "April";
            } else if (incomeData.items[a].month == 5) {
              monthName = "Mei";
            } else if (incomeData.items[a].month == 6) {
              monthName = "Juni";
            } else if (incomeData.items[a].month == 7) {
              monthName = "Juli";
            } else if (incomeData.items[a].month == 8) {
              monthName = "Agustus";
            } else if (incomeData.items[a].month == 9) {
              monthName = "September";
            } else if (incomeData.items[a].month == 10) {
              monthName = "Oktober";
            } else if (incomeData.items[a].month == 11) {
              monthName = "November";
            } else if (incomeData.items[a].month == 12) {
              monthName = "Desember";
            }

            income.push({
              ref_name: monthName,
              price: incomeData.items[a].amount,
            });
          } else {
            let monthName;
            if (b == 1) {
              monthName = "Januari";
            } else if (b == 2) {
              monthName = "Februari";
            } else if (b == 3) {
              monthName = "Maret";
            } else if (b == 4) {
              monthName = "April";
            } else if (b == 5) {
              monthName = "Mei";
            } else if (b == 6) {
              monthName = "Juni";
            } else if (b == 7) {
              monthName = "Juli";
            } else if (b == 8) {
              monthName = "Agustus";
            } else if (b == 9) {
              monthName = "September";
            } else if (b == 10) {
              monthName = "Oktober";
            } else if (b == 11) {
              monthName = "November";
            } else if (b == 12) {
              monthName = "Desember";
            }

            income.push({
              ref_name: monthName,
              price: 0,
            });
          }

          b++;
        }

        typePeriod = "Bulan";
      } else if (this.tooltipPeriod == "thisWeek") {
         let b = 0
        let counter = incomeData.start
        for (let a = 0 ;a <= incomeData.days; a++) {

          let month
          let startMonth = moment().startOf('week').format('MM')
          let monthName = this.month(parseInt(startMonth))
  
          if (typeof incomeData.items[b] !== "undefined") {
            
            // if(response.items[b].day < response.start){
            //   month = moment().add(1,'months').format('MM')
            //   monthName = this.month(parseInt(month))
            // }else if(counter < response.start){
            //   month = moment().add(1,'months').format('MM')
              monthName = this.month(parseInt(response.items[b].month))
            // }  

            // if(incomeData.items[b].day == counter){
              
              income.push({
                ref_name: parseInt(incomeData.items[b].day)+"-"+monthName ,
                price: incomeData.items[b].amount,
              });
              b++
            // }else{
            //   income.push({
            //     ref_name: parseInt(counter)+"-"+monthName ,
            //     price: 0,
            //   });
              
              
            // }
            
          }else{
            if(counter < response.start){
              month = moment().add(1,'months').format('MM')
              monthName = this.month(parseInt(month))
            }
            income.push({
                ref_name: parseInt(counter)+"-"+monthName ,
                price: 0,
              });
              
              
          }
          counter++;
          
        } 

        typePeriod = "Tanggal"
       
      }

      if (
        this.tooltipPeriod != "thisYear" &&
        this.tooltipPeriod != "thisWeek"
      ) {
        let b = 0;
        let counter = 1;
        for (let a = 0; a <= outcomeData.days; a++) {
          if (typeof outcomeData.items[b] !== "undefined") {
            if (outcomeData.items[b].day == counter) {
              outcome.push({
                ref_name: parseInt(outcomeData.items[b].day),
                price: outcomeData.items[b].amount,
              });
              b++;
            } else {
              outcome.push({
                ref_name: parseInt(counter),
                price: 0,
              });
            }
          } else {
            outcome.push({
              ref_name: parseInt(counter),
              price: 0,
            });
          }
          counter++;
        }

        typePeriod = "Tanggal";
      } else if (this.tooltipPeriod == "thisYear") {
        let b = 1;
        for (let a = 0; a < 12; a++) {
          if (typeof outcomeData.items[a] !== "undefined") {
            let monthName;
            if (outcomeData.items[a].month == 1) {
              monthName = "Januari";
            } else if (outcomeData.items[a].month == 2) {
              monthName = "Februari";
            } else if (outcomeData.items[a].month == 3) {
              monthName = "Maret";
            } else if (outcomeData.items[a].month == 4) {
              monthName = "April";
            } else if (outcomeData.items[a].month == 5) {
              monthName = "Mei";
            } else if (outcomeData.items[a].month == 6) {
              monthName = "Juni";
            } else if (outcomeData.items[a].month == 7) {
              monthName = "Juli";
            } else if (outcomeData.items[a].month == 8) {
              monthName = "Agustus";
            } else if (outcomeData.items[a].month == 9) {
              monthName = "September";
            } else if (outcomeData.items[a].month == 10) {
              monthName = "Oktober";
            } else if (outcomeData.items[a].month == 11) {
              monthName = "November";
            } else if (outcomeData.items[a].month == 12) {
              monthName = "Desember";
            }

            outcome.push({
              ref_name: monthName,
              price: outcomeData.items[a].amount,
            });
          } else {
            let monthName;
            if (b == 1) {
              monthName = "Januari";
            } else if (b == 2) {
              monthName = "Februari";
            } else if (b == 3) {
              monthName = "Maret";
            } else if (b == 4) {
              monthName = "April";
            } else if (b == 5) {
              monthName = "Mei";
            } else if (b == 6) {
              monthName = "Juni";
            } else if (b == 7) {
              monthName = "Juli";
            } else if (b == 8) {
              monthName = "Agustus";
            } else if (b == 9) {
              monthName = "September";
            } else if (b == 10) {
              monthName = "Oktober";
            } else if (b == 11) {
              monthName = "November";
            } else if (b == 12) {
              monthName = "Desember";
            }

            outcome.push({
              ref_name: monthName,
              price: 0,
            });
          }

          b++;
        }

        typePeriod = "Bulan";
      } else if (this.tooltipPeriod == "thisWeek") {
        let b = 0;
        let numberMonth = moment()
          .subtract(1, "months")
          .daysInMonth();
        console.log(numberMonth, "===========================");
        let counter = outcomeData.start;
        let endCounter = outcomeData.end;

        if (counter > endCounter) {
        }

        for (let a = 0; a <= outcomeData.days; a++) {
          if (typeof outcomeData.items[b] !== "undefined") {
            // if (outcomeData.items[b].day == counter) {
              outcome.push({
                ref_name: parseInt(outcomeData.items[b].day),
                price: outcomeData.items[b].amount,
              });
              b++;
            // } else {
            //   outcome.push({
            //     ref_name: parseInt(counter),
            //     price: 0,
            //   });
            // }
          } else {
            outcome.push({
              ref_name: parseInt(counter),
              price: 0,
            });
          }
          counter++;
        }

        typePeriod = "Tanggal";
      }

      this.series[0].data = income.map((income) => parseFloat(income.price));
      this.series[1].data = outcome.map((outcome) => parseFloat(outcome.price));
      window.arrData = income.map((income) => String(income.ref_name));
      this.chartLoad = true;

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: window.arrData,
            title: {
              text: "Periode " + typePeriod,
            },
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.getData();
    if (this.chartLoad == true) {
    }

    // this.$root.$on('filterChart',(tool)=>{
    //   console.log(tool,"============ tool ================");
    //   this.Filter(tool)
    // })
    // reference; kt_stats_widget_12_chart
  },
};
</script>
