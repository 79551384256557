<template>
  <div>
    
    <div class="row">

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-start mb-5">
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisWeek"
                @click="Filter('thisWeek')"
              >
                Minggu Ini
              </button>
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisMonth"
                @click="Filter('thisMonth')"
              >
                Bulan Ini
              </button>
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.lastMonth"
                @click="Filter('lastMonth')"
              >
                Bulan Kemarin
              </button>
              <button
                type="button"
                :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisYear"
                @click="Filter('thisYear')"
              >
                Tahun Ini
              </button>
              <!-- <button
              type="button"
              class="btn btn-outline-primary filter-btn mx-1"
              @click="periodFilter()"
            ></button> -->
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <apexchart
          class="card-rounded-bottom"
          :options="chartOptions"
          :series="series"
          type="bar"
          width="100%"
          height="350"
          
        ></apexchart>
      </div>
    </div>
    
    
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
export default {
  name: "widget-12",
  data() {
    return {
      chartOptions: {},
      series: [
        {
          name: "Jumlah ",
          data: [],
        },
      ],
      sum: "",
      arr: [],
      chartLoad: false,
      sumLoad: false,
      // filter
      filter: {
        start_date: "",
        end_date: "",
      },
      period: {
        thisWeek: "btn-purple",
        thisMonth: "btn-outline-purple",
        lastMonth: "btn-outline-purple",
        thisYear: "btn-outline-purple",
      },
      
      tooltipPeriod: "thisWeek",

      // chart
      // series: [],
      chartOptions: {
        chart: {
          type: "bar",
          
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
            borderRadius: 10
          },
        },
        dataLabels: {
          enabled: false,
        },
        // stroke: {
        //   show: true,
        //   width: 2,
        //   colors: ['transparance'],
        // },
        xaxis: {
          categories: [],
        },
        // yaxis: {
        //   title: {
        //     text: "$ (thousands)",
        //   },
        // },
        fill: {
          opacity: 1,
        },
        // colors:['#4f1669'],
        colors:['#245590'],
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        title: {
            text: "Statistik Tindakan Medis Per-Layanan",
            align: 'left',
            margin: 10,
            offsetX: 0,
            offsetY: 0,
            floating: false,
            style: {
              fontSize:  '14px',
              fontWeight:  'bolder',
              // fontFamily:  undefined,
              // color:  '#263238'
            },
        }
      },
    };
  },
  methods: {
    Filter(tool) {
      this.tooltipPeriod = tool;
      this.chartLoad = false;

       if (tool == "thisWeek") {
        this.period.thisWeek = "btn-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "lastMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisYear") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-purple";
      }

      this.getData();

      mapGetters(["layoutConfig"]);
    },
    // generalFilter() {
    //   this.tooltipPeriod = "custom";
    //   this.chartLoad = false;
    //   this.getData();
    //   mapGetters(["layoutConfig"]);
    // },
    // periodFilter() {
    //   this.tooltipPeriod = "custom";
    // },
    // resetFilter() {
    //   this.chartLoad = false;
    //   this.filter.start_date = "";
    //   this.filter.end_date = "";
    //   this.getData();
    //   mapGetters(["layoutConfig"]);
    // },

    async getData(tool) {
      window.arrData = [];
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get("graphic-medical-action/service", `${filterParams}`);
      
      let res = response.items
      let patient = [];
      let sortedArr = []

      for(let key in res){
        sortedArr.push(res[key])
      }
    
      sortedArr.sort(function(a, b) {
          a = a.value;
          b = b.value;

          return a > b ? -1 : (a > b ? 1 : 0);
      });
      

      for(let key in sortedArr){
        patient.push({
          ref_name: sortedArr[key].name ,
          price: sortedArr[key].value,
        });
      }
   
      this.series[0].data = patient.map((patient) => parseFloat(patient.price));
      window.arrData = patient.map((patient) => String(patient.ref_name));
      this.chartLoad = true;

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          xaxis: {
            categories: window.arrData,
            title: {
              text: 'Jenis Layanan ',
            },
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.getData();
    if (this.chartLoad == true) {
      
    }

    // this.$root.$on('filterChart',(tool)=>{
    //   console.log(tool,"============ tool ================");
    //   this.Filter(tool)
    // })
    // reference; kt_stats_widget_12_chart
  },
};
</script>


