<template>
  <div>
    <div class="row">
      <!-- <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 d-flex justify-content-start mb-5">
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisWeek"
              @click="Filter('thisWeek')"
            >
              Minggu Ini
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisMonth"
              @click="Filter('thisMonth')"
            >
              Bulan Ini
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.lastMonth"
              @click="Filter('lastMonth')"
            >
              Bulan Kemarin
            </button>
            <button
              type="button"
              :class="'btn btn-sm filter-btn mx-1 ' + this.period.thisYear"
              @click="Filter('thisYear')"
            >
              Tahun Ini
            </button>
          </div>
        </div>
      </div> -->

      <div class="col-md-12">
        <span class="text-secondary"
          >Rata-rata diambil berdasarkan data perjanjian 2 bulan terakhir</span
        >
      </div>

      <div class="col-md-12">
        <div class="row pt-2" v-if="items != []">
          <div
            class="col-md-4 mb-2"
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="card shadow-sm" style="border-radius: 20px;">
              <div class="card-body p-4">
                <div class="row">
                  <div class="col-md-5 p-2">
                    <div
                      style="object-fit: contain; width: 120px; height: 120px"
                    >
                      <img
                        class="card-img-top"
                        style="object-fit: contain; width: 100%; height: 100%"
                        :src="item.photo"
                        alt="photo"
                      />
                    </div>
                  </div>

                  <div class="col-md-7 p-2">
                    <div class="d-block">
                      <span
                        class="d-block font-weight-bold"
                        style="font-size: 15px"
                        >{{
                          item.name + " (" + item.polyclinic_name + ")"
                        }}</span
                      >
                      <!-- <span class="d-block" style="font-size: 11px">{{
                        item.polyclinic_name
                      }}</span> -->
                    </div>
                    <div class="d-block mt-2">
                      <span
                        class="font-weight-bold d-block"
                        style="font-size: 12px"
                        >rata-rata :</span
                      >

                      <div class="ml-4">
                        <span class="d-block" style="font-size: 12px"
                          >Perjanjian : {{ item.avgData.avgAppointment }}</span
                        >
                        <span class="d-block" style="font-size: 12px"
                          >Pasien : {{ item.avgData.avgPatient }}</span
                        >
                        <span class="d-block" style="font-size: 12px"
                          >Tindakan : {{ item.avgData.avgMedicalAction }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <b-card
                :img-src="item.photo"
                img-alt="Image"
                img-top
                style="max-width: 20rem;"
                class="mb-1 p-1"
                no-body
                >
                <b-card-body>
                    <span class="d-block font-weight-bold" style="font-size:12px">{{item.name}}</span>
                    <span class="d-block" style="font-size:11px">{{item.polyclinic_name}}</span>
                </b-card-body>
                <b-card-text>
                    <span class="d-block font-weight-bold" style="font-size:12px">{{item.name}}</span>
                    <span class="d-block" style="font-size:11px">{{item.polyclinic_name}}</span>
                </b-card-text>

                </b-card> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
export default {
  name: "widget-12",
  data() {
    return {
      // filter
      filter: {
        start_date: "",
        end_date: "",
      },
      period: {
        thisWeek: "btn-purple",
        thisMonth: "btn-outline-purple",
        lastMonth: "btn-outline-purple",
        thisYear: "btn-outline-purple",
      },
      tooltipPeriod: "thisWeek",

      // data
      items: [],
    };
  },
  methods: {
    Filter(tool) {
      this.tooltipPeriod = tool;
      this.chartLoad = false;
      if (tool == "thisWeek") {
        this.period.thisWeek = "btn-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "lastMonth") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-purple";
        this.period.thisYear = "btn-outline-purple";
      } else if (tool == "thisYear") {
        this.period.thisWeek = "btn-outline-purple";
        this.period.thisMonth = "btn-outline-purple";
        this.period.lastMonth = "btn-outline-purple";
        this.period.thisYear = "btn-purple";
      }
      this.getData();

      mapGetters(["layoutConfig"]);
    },

    async getData() {
      // let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      // let response = await module.get("active-doctor", `${filterParams}`);
      let response = await module.get("active-doctor");

      this.items = response;
    },
  },

  mounted() {
    this.getData();
  },
};
</script>


