<template>
  <div>
    <!--begin::Chart-->
    <apexchart
      class="card-rounded-bottom"
      :options="chartOptions"
      :series="series"
      type="donut"
      width="100%"
      height="390"
      
    ></apexchart>
    <!--end::Chart-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";
export default {
  name: "widget-12",
  data() {
    return {
      chartOptions: {},
      // series: [
      //   {
      //     name: "Jumlah :",
      //     data: [],
      //   },
      // ],
      sum: "",
      arr: [],
      chartLoad: false,
      sumLoad: false,
      // filter
      filter: {
        start_date: "",
        end_date: "",
      },
      
      tooltipPeriod: "thisWeek",

      // chart
      series: [],
      chartOptions: {
        customScale: 0.8,
        labels: [],
        chart: {
          type: 'donut',
           categories: [],
        },
          xaxis: {
          categories: [],
        },
        legend: {
          position: 'bottom'
        },
        colors:['#245590','#f47b20'],
      },
    };
  },
  methods: {
    Filter(tool) {
      this.tooltipPeriod = tool;
      this.chartLoad = false;
      this.getData();

      mapGetters(["layoutConfig"]);
    },
    generalFilter() {
      this.tooltipPeriod = "custom";
      this.chartLoad = false;
      this.getData();
      mapGetters(["layoutConfig"]);
    },
    periodFilter() {
      this.tooltipPeriod = "custom";
    },
    resetFilter() {
      this.chartLoad = false;
      this.filter.start_date = "";
      this.filter.end_date = "";
      this.getData();
      mapGetters(["layoutConfig"]);
    },

    async getData(tool) {
      window.arrData = [];
      let filterParams = `?start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&filter=${this.tooltipPeriod}`;
      let response = await module.get("graphic-patient/gender", `${filterParams}`);
      
      let patient = [];
      let gender = [];
    
      //  gender
      for (let b = 0; b < response.items.length; b++) {
        if(response.items[b].gender == "male"){
          patient.push(response.items[b].amount)
          // gender.push(response.items[b].gender)
          gender.push("Laki-laki")
        }else if(response.items[b].gender == "female"){
          patient.push(response.items[b].amount)
          // gender.push(response.items[b].gender)
          gender.push("Perempuan")
          
        }
      }

      

      // this.series = patient.map((patient) => parseFloat(patient.price));
      this.series = patient
      window.arrData = gender;
      console.log(window.arrData,"window.arrData");
      this.chartLoad = true;

      this.chartOptions = {
        ...this.chartOptions,
        ...{
          labels : window.arrData,
          xaxis: {
            categories: window.arrData,
            title: {
              text: 'Persentase Jenis Kelamin',
            },
          },
        },
      };
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.getData();
    if (this.chartLoad == true) {
      
    }

    this.$root.$on('filterChart',(tool)=>{
      console.log(tool,"============ tool ================");
      this.Filter(tool)
    })
    // reference; kt_stats_widget_12_chart
  },
};
</script>


